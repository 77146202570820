<template>
  <v-card tile min-width="25%">
    <v-img
      class="white--text align-end"
      height="300px"
      contain
      :src="`/webbilder/${product.id}.png`??`/webbilder/1.png`"
    >
    </v-img>
    <v-card-title>{{ product.name }}</v-card-title>

    <v-card-text class="text--primary text-h6">
      <div>{{ product.price }} €</div>
    </v-card-text>

    <v-card-actions>
      <v-btn class="ma-2" text icon :color="wishlist" @click="getWishlist">
        <v-icon>mdi-heart</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="ma-2 green darken-4" @click="selectProduct" dark> Details </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      wishlist: 'grey',
    };
  },
  created() {
    this.wishlist = this.inWishlist();
  },
  methods: {
    inWishlist() {
      const w = JSON.parse(localStorage.getItem('wishlist'));
      if (w.find((e) => e == this.product.id)) {
        return 'red';
      } else {
        return 'grey';
      }
    },
    getWishlist() {
      let wlist = JSON.parse(localStorage.getItem('wishlist'));

      if (wlist.find((el) => this.product.id == el)) {
        wlist = wlist.filter((e) => e != this.product.id);
      } else {
        wlist.push(this.product.id);
      }
      localStorage.setItem('wishlist', JSON.stringify(wlist));
      this.wishlist = this.inWishlist();
    },
    selectProduct() {
      // localStorage.setItem('product', JSON.stringify(this.product));
      this.$router.push({ path: `/Produkt/${this.product.id}` });
    },
  },
};
</script>

<style lang="scss" scoped></style>
