<template>
  <v-container fluid class="pa-0">
    <!-- <v-col cols="12" class="py-2 d-flex justify-center">
      <p>Filter</p>
    </v-col> -->
    <div class="container text-center">
      <h2 class="text-h4 font-weight-thin mb-4">So funktioniert's:</h2>
      <h2 class="text-h4 font-weight-thin mb-4">Produkte jetzt online bestellen und in der Kantine abholen</h2>
    </div>
    <v-parallax dark src="../assets/platz2.jpg">
      <v-row align="center" justify="center">
        <v-col cols="4" class="d-flex justify-center">
          <v-img
            class="white--text align-end"
            contain
            src="/webbilder/11.png"
          >
          </v-img>
        </v-col>
        <v-col class="text-center" cols="8">
          <h1 class="text-h4 font-weight-thin mb-4">FCP Trikot</h1>
          <h4 class="subheading">Bestelle dir jetzt dein eigenes FC Purkersdorf Trikot</h4>
          <v-btn rounded class="ma-3" to="/Produkt/11">Jetzt Bestellen</v-btn>
        </v-col>
      </v-row>
    </v-parallax>
    <!-- <v-col cols="12" class="py-2 d-flex justify-center">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        style="max-width: 50%"
        class="white rounded-pill pa-4"
      ></v-text-field>
    </v-col> -->
    <v-row class="justify-center pa-0" no-gutters>
      <v-col
        cols="12"
        md="6"
        lg="3"
        class="flex-grow-1 flex-shrink-0"
        v-for="product in filterdProducts"
        :key="product.id"
      >
        <ProductCard :product="product" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
export default {
  name: 'Home',

  components: {
    ProductCard,
  },
  props: {
    products: {
      type: Array,
    },
  },
  computed: {
    filterdProducts() {
      if (!this.search) {
        return this.products;
      } else {
        return this.products.filter((p) =>
          p.name.toUpperCase().includes(this.search.toUpperCase()),
        );
      }
    },
  },
  data() {
    return {
      search: '',
    };
  },
};
</script>
