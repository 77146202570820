import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import axios from 'axios';

// Hilfsfunktion zum Prüfen der Session
const checkSession = async () => {
  // return true;
  try {
    const response = await axios.get('/check-session', {
      withCredentials: true,
    });
    console.log('Session geprüft', response.data.loggedIn);
    return response.data.loggedIn;
  } catch (error) {
    console.error('Fehler beim Überprüfen der Session', error);
    return false;
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Wishlist.vue'),
  },
  {
    path: '/produkt/:id',
    name: 'produkt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Produkt.vue'),
  },
  {
    path: '/cart',
    name: 'cart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Cart.vue'),
  },
  {
    path: '/kassa',
    name: 'kassa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Kassa.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    beforeEnter: async (to, from, next) => {
      const loggedIn = await checkSession();
      if (loggedIn) {
        next(); // Wenn eingeloggt, weiter zur Admin-Seite
      } else {
        next('/login'); // Wenn nicht eingeloggt, zur Login-Seite umleiten
      }
    },
  },
  {
    path: '/admin/products',
    name: 'admin-products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminProducts.vue'),
    beforeEnter: async (to, from, next) => {
      const loggedIn = await checkSession();
      if (loggedIn) {
        next(); // Wenn eingeloggt, weiter zur Admin-Seite
      } else {
        next('/login'); // Wenn nicht eingeloggt, zur Login-Seite umleiten
      }
    },
  },
  {
    path: '/admin/orders',
    name: 'admin-orders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminOrders.vue'),
    beforeEnter: async (to, from, next) => {
      const loggedIn = await checkSession();
      if (loggedIn) {
        next(); // Wenn eingeloggt, weiter zur Admin-Seite
      } else {
        next('/login'); // Wenn nicht eingeloggt, zur Login-Seite umleiten
      }
    },
  },
  {
    path: '/admin/orders/:id',
    name: 'admin-order-details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminOrderDetails.vue'),
    beforeEnter: async (to, from, next) => {
      const loggedIn = await checkSession();
      if (loggedIn) {
        next(); // Wenn eingeloggt, weiter zur Admin-Seite
      } else {
        next('/login'); // Wenn nicht eingeloggt, zur Login-Seite umleiten
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
    beforeEnter: async (to, from, next) => {
      const loggedIn = await checkSession();
      if (loggedIn) {
        next(); // Wenn eingeloggt, weiter zur Admin-Seite
      } else {
        next('/login'); // Wenn nicht eingeloggt, zur Login-Seite umleiten
      }
    },
  },
  {
    path: '/orderdone',
    name: 'orderdone',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderDone.vue'),
  },
  {
    path: '/impressum',
    name: 'Impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Impressum.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
