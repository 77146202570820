import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios';

axios.interceptors.request.use(
  (config) => {
    // Füge den API-Key zum Header hinzu
    config.headers['Authorization'] = '4e1fad6d-77d9-4081-ae93-79c420232153';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
