<template>
  <v-app>
    <v-app-bar v-if="$route.name !== 'login' && !$route.name?.includes('admin')" app dark>
      <div class="d-flex align-center" style="position:absolute; top:0px;">
        <router-link to="/">
          <v-img
            alt="FCP"
            class="mr-2"
            contain
            src="./assets/logo.svg"
            transition="scale-transition"
            height="100px"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" text icon to="/wishlist">
        <v-icon>mdi-heart</v-icon>
      </v-btn>
      <v-btn class="ma-2" text icon to="/cart">
        <v-badge :value="cart" :content="cart"><v-icon>mdi-cart</v-icon></v-badge>
      </v-btn>
    </v-app-bar>
    <v-app-bar v-else app dark>
      <div class="d-flex align-center" >
        <router-link to="/admin">
          <v-btn class="ma-2" text>admin</v-btn>
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" text @click="logout">
        logout
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view :products="products" />
    </v-main>

    <v-footer dark padless>
      <v-card flat tile class="green darken-4 white--text text-center" width="100%">
        <v-card-text>
          <v-btn
            class="mx-4 white--text"
            icon
            href="https://www.facebook.com/fcpurkersdorf"
            target="_blank"
          >
            <v-icon size="24px"> mdi-facebook </v-icon>
          </v-btn>
          <v-btn
            class="mx-4 white--text"
            icon
            href="https://www.instagram.com/fc_purkersdorf/"
            target="_blank"
          >
            <v-icon size="24px"> mdi-instagram </v-icon>
          </v-btn>
          <v-btn class="mx-4 white--text" icon>
            <v-icon size="24px"> mdi-email </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          Official FC Purkersdorf Fanshop - Created by <strong>Victor Pawlek</strong>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>FC Purkersdorf</strong>
        </v-card-text>
        <v-card-text class="white--text pt-0">
          <v-btn text to="/impressum">Impressum</v-btn>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import axios from 'axios';
import router from './router';
export default {
  name: 'App',

  async created() {
    this.products = await this.getProducts();
    if (!JSON.parse(localStorage.getItem('wishlist'))) {
      localStorage.setItem('wishlist', JSON.stringify(new Array()));
    }
    if (!JSON.parse(localStorage.getItem('cart'))) {
      localStorage.setItem('cart', JSON.stringify(new Array()));
    }
    let t = 0;
    let lcart = JSON.parse(localStorage.getItem('cart'));
    lcart.forEach((element) => {
      t += element.anzahl;
    });
    this.cart = t;
  },

  data() {
    return {
      products: [],
      cart: 0,
    };
  },
  methods: {
    async getProducts() {
      const products = await axios({ method: 'GET', url: '/products' });
      return products.data;
    },
    async logout() {
      try {
        const response = await axios.post('/logout');
        console.log(response.data);
        router.push('/login');
      } catch (error) {
        console.error('Fehler beim Logout', error);
      }
    },
  },
  mounted() {
    window.addEventListener('cart-changed', (event) => {
      let t = 0;
      JSON.parse(event.detail.storage).forEach((element) => {
        t += element.anzahl;
      });
      this.cart = t;
    });
  },
};
</script>

<style>
.v-main {
  background-color: white;
}
</style>
